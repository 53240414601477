// Don't want to import from @lz-graphql. This should maybe go in @lz-graphql
export function getAuthData() /*: SignIn | AuthToken | null*/ {
  // TODO: Use WebUI SessionStorageService - Will require refactor once merged in
  const authDataJson = sessionStorage.getItem("authData");
  const authData = authDataJson ? JSON.parse(authDataJson) : authDataJson;
  return authData;
}

export function clearAuthData() {
  sessionStorage.setItem("authData", "");
  sessionStorage.setItem("authToken", ""); // TODO: Remove. Currently still being used internally in webui which affects routing
}
