var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { addErrorHandler, navigateToUrl, registerApplication, start, } from "single-spa";
import { clearAuthData, getAuthData } from "@lz-vanilla/auth";
import { splashScreen } from "@lz-vanilla/dom";
window.showSpaBar = function () {
    document.querySelector(".single-spa-navbar").style.setProperty("display", "flex");
};
registerApplication({
    name: "@LIFELENZ/html-app",
    // @ts-ignore
    app: import("@html-app/app"),
    activeWhen: function (location) { return isHtmlApp(location); },
});
registerApplication({
    name: "@LIFELENZ/aurelia-app",
    // @ts-ignore
    app: function () { return import("aureliaApp/app"); },
    activeWhen: function (location) { return isAureliaApp(location); },
    customProps: function () {
        return {
            data: {
                name: "aurelia-app",
                /// returnUrl: "asdf",
                // user: user,
                // locale: locale,
            },
        };
    },
});
registerApplication({
    name: "@LIFELENZ/react-app",
    app: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // return System.import("@LIFELENZ/react-app") as Promise<
            //   LifeCycles<{ data: { name: string } }>
            // >;
            // import via ES6 + Module Federation
            // @ts-ignore
            return [2 /*return*/, import("reactApp/app")];
        });
    }); },
    activeWhen: function (location) { return isReactApp(location); },
});
registerApplication({
    name: "@LIFELENZ/webui",
    // @ts-ignore
    app: function () { return import("webui/app"); },
    // if url starts with a stack code
    activeWhen: function (location) { return isWebUI(location); },
    customProps: function () {
        return {
            data: {
                name: "webui",
                // returnUrl: "asdf",
                // user: user,
                // locale: locale,
            },
        };
    },
});
registerApplication({
    name: "@LIFELENZ/webui-auth",
    app: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // import via ES6 + Module Federation
            // @ts-ignore
            return [2 /*return*/, import("webuiAuth/app")];
        });
    }); },
    activeWhen: function (location) { return isAuthApp(location); },
    customProps: function () {
        return {
            data: {
                name: "auth",
                // returnUrl: "asdf",
                // user: user,
                // locale: locale,
            },
        };
    },
});
registerApplication({
    name: "@LIFELENZ/webui-account",
    app: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // @ts-ignore
            return [2 /*return*/, import("webuiAccount/app")];
        });
    }); },
    activeWhen: function (location) { return isAccountApp(location); },
    customProps: function () {
        return {
            data: {
                name: "account",
                // returnUrl: "asdf",
                // user: user,
                // locale: locale,
            },
        };
    },
});
addErrorHandler(function (e) {
    console.error("AN ERROR OCCURED", e);
});
window.addEventListener("single-spa:before-app-change", function (event) {
    console.log("single-spa is about to mount/unmount applications!", event);
});
window.addEventListener("single-spa:before-first-mount", function (event) {
    console.log("single-spa is about to mount the very first application for the first time", event);
});
window.addEventListener("single-spa:first-mount", function (event) {
    splashScreen("hidden");
    console.log("single-spa is about to mount the very first application for the first time", event);
});
function isRootUrlPattern() {
    return /^\/[a-z]{2}\d{2}\/?$/.test(location.pathname);
}
function isWebUI(location) {
    if (isHtmlApp(location) ||
        isReactApp(location) ||
        isAureliaApp(location) ||
        isAuthApp(location) ||
        isAccountApp(location)) {
        return false;
    }
    return !!getAuthData() && /^\/[a-z]{2}\d{2}\/?/.test(location.pathname);
}
function isAuthApp(location) {
    return ((isRootUrlPattern() && !getAuthData()) ||
        /^\/[a-z]{2}\d{2}\/auth/.test(location.pathname));
}
function isHtmlApp(location) {
    return location.pathname.startsWith("/au01/html-app");
}
function isReactApp(location) {
    return location.pathname.startsWith("/au01/react-app");
}
function isAureliaApp(location) {
    return location.pathname.startsWith("/au01/aurelia-app");
}
function isAccountApp(location) {
    return /^\/[a-z]{2}\d{2}\/account/.test(location.pathname);
}
window.addEventListener("single-spa:before-routing-event", function (event) {
    var _a;
    var detail = event.detail;
    console.log("single SPA is going to change route", event);
    var authData = getAuthData();
    var isRoot = isRootUrlPattern();
    // if root, just let activeWhen handle it (Got auth? load webui. No Auth? login screen will load)
    // if !isRoot, got some pre-load work to do.
    if (!isRoot) {
        if (isAuthApp(window.location) || isAccountApp(window.location)) {
            // if heading to an unauthenticated part of the site, clear auth data if there is any
            if (authData) {
                clearAuthData();
                location.reload(); // This hack is needed for the "single-nav-app" bar demonstration
            }
        }
        else if (!authData) {
            // If heading heading to a non-auth part of the site, but there's no auth data redirect to login screen
            (_a = detail.cancelNavigation) === null || _a === void 0 ? void 0 : _a.call(detail); // // https://single-spa.js.org/docs/api/#canceling-navigation
            navigateToUrl("/au01");
            console.log("No Auth Data - redirecting to login app");
        }
    }
});
window.addEventListener("single-spa:before-first-mount", function (event) {
    console.log("single-spa is about to mount the very first application for the first time", event);
});
window.addEventListener("single-spa:before-app-change", function (event) {
    console.log("single SPA is going to change app", event);
});
window.addEventListener("single-spa:app-change", function (event) {
    console.log("A routing event occurred where at least one application was mounted/unmounted", event);
    // console.log(evt.detail.originalEvent); // PopStateEvent
    // console.log(evt.detail.newAppStatuses); // { app1: MOUNTED, app2: NOT_MOUNTED }
    // console.log(evt.detail.appsByNewStatus); // { MOUNTED: ['app1'], NOT_MOUNTED: ['app2'] }
    // console.log(evt.detail.totalAppChanges); // 2
});
window.addEventListener("single-spa:no-app-change", function (evt) {
    console.log("A routing event occurred where zero applications were mounted/unmounted");
    // console.log(evt.detail.originalEvent); // PopStateEvent
    // console.log(evt.detail.newAppStatuses); // { }
    // console.log(evt.detail.appsByNewStatus); // { MOUNTED: [], NOT_MOUNTED: [] }
    // console.log(evt.detail.totalAppChanges); // 0
});
start({
    urlRerouteOnly: true,
});
